import React from "react"
import { graphql, PageProps } from "gatsby"
import { ArticlesPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ArticlesPageLayout from "../layouts/articles-page-layout"

const ComoMoves: React.FC<PageProps<ArticlesPageProps>> = ({ data }) => {
  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <ArticlesPageLayout
        loadMore={data?.PAGE_CONTENT?.articlesOptions?.loadMoreArticles}
        pageTitle="Como Bewegt"
        articles={data?.ARTICLES?.nodes}
        parent="como-moves"
      />
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      slug
      title
      link
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      articlesOptions {
        loadMoreArticles
      }
    }
    ARTICLES: allWpArticle(filter: { locale: { locale: { eq: $locale } } }) {
      nodes {
        id
        uri
        title
        locale {
          locale
        }
        featuredImage {
          node {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
        singleArticleOptions {
          authorSelector {
            ... on WpTeam {
              id
              title
            }
          }
        }
      }
    }
  }
`

export default ComoMoves
